import React, {useEffect, useState} from "react"
import * as dayjs from 'dayjs';
import 'dayjs/locale/nl';

export default function AlbumSlider({albums}) {
    dayjs.locale('nl');

    return (
        <div className={"c-album-slider relative mb-12 grid grid-cols-2 md:grid-cols-2 gap-4"}>
            {albums.slice(0,4).map((album) => (
                <div className={"flex flex-col"} key={album.uri}>
                    <a href={album.external_urls.spotify} rel={"nofollow"} target={"_blank"}>
                        <div>
                            <img src={album.images[0].url} alt={album.name} />
                        </div>
                        <div className={"flex flex-col justify-center p-4 bg-neutral-200"}>
                            <h2 className={"font-bold font-primary text-primary text-3xl mb-1 capitalize "}>{album.name.toLowerCase()}</h2>
                            <p className={"text-secondary font-secondary"}>{dayjs(album.release_date).format('DD MMMM YYYY')}</p>
                        </div>
                    </a>
                </div>
            ))}
        </div>
    );
}
